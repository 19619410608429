.nav-container {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  margin: 0 auto;
  height: 100px;
  background-color: #fff;
}
.nav-container .container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}
.nav-container.shadow{
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  
}
.logo-container {
  width: 150px;
  height: 100px;
}
.logo-container img{
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}
.item-container {
  display: flex;
  min-width: 40%;
  gap: 1rem;
  align-items: center;
}
.item-container .nav-items a {
  margin: 0 10px;
  text-decoration: none;
  color: #555;
  font-size: 20px;
}
.item-container .nav-items a:hover {
  color: #3498db;
}
.language-items {
  padding: 5px 0 5px 10px;
  border: 1px solid #ccc;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  margin-right: 20px;
}
.bars-icon {
  display: none;
}
@media screen and (max-width: 1024px) {
  .logo-container {
    width: 120px;
    height: 60px;
  }
  .item-container {
    min-width: 50%;
  }
  .item-container .nav-items a {
    font-size: 22px;
  }
  .language-items {
    font-size: 18px;
    padding: 4px 0 4px 10px;
    border-radius: 10px;
  }
}

@media screen and (max-width: 960px) {
  .logo-container {
    width: 100px;
    height: 60px;
  }
  .item-container {
    min-width: 30%;
    justify-content: space-evenly;
  }
  .language-items {
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .logo-container {
    width: 80px;
    height: 50px;
  }
  .bars-icon {
    display: block;
    font-size: 25px;
    right: 10px;
    z-index: 999;
    color: #000;
  }
  .item-container .nav-items{
    position: fixed;
    top: 0;
    right: -100%;
    display: none;
    flex-direction: column;
    height: 100vh;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 50%;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    transition: 1s all ease-in;
  }
  .item-container .nav-items.active {
    right: 0;
    display: flex;
  }
  .item-container .nav-items a {
    font-size: 25px;
  }
  .language-items {
    font-size: 16px;
  }
  .bars-icon {
    font-size: 22px;
  }
}
@media screen and (max-width:480px) {
  .item-container {
    width: 70%;
  }
  .item-container .nav-items {
    width: 60%;
    padding: 0 20px;
  }
}
@media screen and (max-width: 380px) {
  .item-container .nav-items {
    width: 70%;
    gap: 2rem;
  }
  .item-container {
    width: 60%;
  }
  .item-container .nav-items a {
    font-size: 15px;
  }
  .language-items {
    font-size: 14px;
  }
  .bars-icon {
    font-size: 20px;
  }
}
