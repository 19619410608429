*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
.container{
  width: 100%;
  max-width: 1450px;
  margin: 0 auto;
  overflow: hidden;
  /* padding: 0 20px; */
}
button{
  border: none;
  /* padding: 10px 40px; */
  font-size: 20px;
  border-radius: 15px;
  cursor: pointer;
  color: #fff;
  background-color: #3498DB;
  transition: all 0.3s ease;
  text-align: center;
}