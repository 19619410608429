.faq-container {
  padding: 20px;
  display: grid;
  gap: 3rem;
  position: relative;
  margin: 0 auto;
}
.faq {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.faq .faq-title h1 {
  font-size: 3rem;
}
.faq-cards {
  display: grid;
  gap: 3rem;
  width: 70%;
}
.faq-cards .faq-card {
  display: grid;
    gap: 1rem;
}
.faq-card .faq-questions{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.faq-questions h2{
    font-size: 1.7rem;
    letter-spacing: 2px;
    color: #555;
}
.faq-questions .icons{
    width: 40px;
    height: 40px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}
.faq-questions .icons .icon{
    font-size: 2rem;
    color: #000;
}
.faq-card .faq-answers p{
    font-size: 1.3rem;
    color: #555;
    line-height: 1.5;
}

.faq-cards2{
    position: relative;
    display: grid;
    gap: 2rem;
}
.faq-cards2 .faq-card2{
    padding: 20px;
    display: grid;
    gap: 1rem;
    position: absolute;
    height: 350px;
    top: 25%;
    left: 25%;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    border-radius: 10px;
}
.faq-card2 .faq-title2 h2{
    font-size: 2rem;
    letter-spacing: 2px;
}
.faq-card2 .faq-inputs2{
    display: grid;
    gap: 2rem;
}
.faq-card2 .faq-inputs2 input{
    padding: 10px;
    width: 100%;
    border: none;
    border-bottom: 2px solid #555;
    outline: none;
    font-size: 1.5rem;
    color: #555;
}
.faq-card2 .faq-buttons2 button{
    padding: 10px 20px;
    border: none;
    outline: none;
    font-size: 1.5rem;
    color: #fff;
    background-color: #3498DB;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
}
.faq-cards2 .faq-image2{
    margin: 0 auto;
    width: 100%;
    height: 600px;
}
.faq-cards2 .faq-image2 img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
@media screen and (max-width:1024px) {
    .faq{
        flex-direction: column;
        gap: 2rem;
        width: 100%;
    }
    .faq .faq-title h1 {
        font-size: 2.5rem;
      }
    .faq-cards {
        grid-template-columns: repeat(1,1fr);
        gap: 2rem;
        width: 100%;
        justify-content: space-between;
    }
    .faq-cards .faq-card{
        padding: 0;
        width: 100%;
    }
    .faq-cards .faq-card .faq-questions h2{
        letter-spacing: 1px;
    }
}
@media screen and (max-width:960px){
    .faq-cards .faq-card .faq-questions h2{
        letter-spacing: 0px;
        font-size: 1.5rem;
    }
    .faq-cards2 .faq-card2{
        left: 15%;
        top: 30%;
        height: 330px;
    }
}

@media screen and (max-width: 768px) {
    .faq .faq-title h1 {
        font-size: 2rem;
    }
    
    .faq-cards .faq-card .faq-questions h2{
        font-size: 1.3rem;
    }
    .faq-cards .faq-card .faq-questions .icons{
        width: 35px;
        height: 35px;
    }
    .faq-cards .faq-card .faq-questions .icons .icon{
        font-size: 1.5rem;
    }
    .faq-cards .faq-card .faq-answers p{
        font-size: 1.3rem;
    }
    .faq-cards2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .faq-cards2 .faq-card2{
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 50%;
        left: 25%;
    }
    .faq-card2 .faq-title2 h2{
        font-size: 1.5rem;
    }
    .faq-card2 .faq-inputs2 input{
        padding: 10px;
        width: 100%;
        border: none;
        border-bottom: 2px solid #555;
        outline: none;
        font-size: 1.3rem;
    }
    .faq-card2 .faq-buttons2 button{
        padding: 10px 20px;
        border: none;
        outline: none;
        font-size: 1.3rem;
        border-radius: 5px;
        width: 100%;
    }
    .faq-cards2 .faq-image2{
        width: 100%;
    }
}

@media screen and (max-width:480px) {
    .faq .faq-title h1 {
        font-size: 1.5rem;
    }
    .faq-cards .faq-card .faq-questions h2{
        font-size: 1rem;
    }
    .faq-cards .faq-card .faq-questions .icons{
        width: 30px;
        height: 30px;
    }
    .faq-cards .faq-card .faq-questions .icons .icon{
        font-size: 1rem;
    }
    .faq-cards .faq-card .faq-answers p{
        font-size: 0.8rem;
    }
    .faq-cards2{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;;
    }
    .faq-cards2 .faq-card2{
        display: flex;
        gap: 1rem;
        width: 250px;
        left: 20%;
        margin: 0 auto;
        height: 280px;
    }
    .faq-card2 .faq-title2 h2{
        font-size: 1.3rem;
    }
    .faq-card2 .faq-inputs2 input{
        padding: 10px;
        width: 100%;
        font-size: 1rem;
    }
    .faq-card2 .faq-buttons2 button{
        padding: 10px 20px;
        border: none;
        outline: none;
        font-size: 1rem;
        border-radius: 5px;
        width: 100%;
    }
}
@media screen and (max-width:380px) {
    .faq .faq-title h1 {
        font-size: 1.3rem;
    }
    .faq-cards .faq-card .faq-questions h2{
        font-size: 0.6rem;
    }
    .faq-cards .faq-card .faq-questions .icons{
        width: 25px;
        height: 25px;
    }
    .faq-cards .faq-card .faq-questions .icons .icon{
        font-size: 0.8rem;
    }
    .faq-cards .faq-card .faq-answers p{
        font-size: 0.5rem;
    }
    .faq-cards2 .faq-card2{
        width: 90%;
        left: 5%;
        top: 20%;
        max-height: 240px;
    }.faq-cards2 .faq-image2{
        max-height: 400px;
    }
    .faq-card2 .faq-title2 h2{
        font-size: 0.9rem;
    }
    .faq-card2 .faq-inputs2 input{
        padding: 10px;
        width: 100%;
        font-size: 0.8rem;
    }
    .faq-inputs2 input::placeholder{
        font-size: 0.7rem;
    }
    .faq-card2 .faq-buttons2 button{
        padding: 10px;
        font-size: 0.8rem;
    }
    .faq-cards .faq-card .faq-questions .icons{
        width: 25px;
        height: 25px;
    }
    .faq-cards .faq-card .faq-questions .icons .icon{
        font-size: 1rem;
    }
}
