.main-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 100px auto 0 auto;
    padding: 20px;
    /* height: 100vh; */
}
.main-left-section{
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 3rem;
}
.main-left-section .left-title{
    display: grid;
    gap: 2rem;
}
.main-left-section .left-title h1{
    font-size: 5rem;
    font-weight: 600;
    color: #3498DB;
    letter-spacing: 4px;
}
.main-left-section .left-title p{
    width: 70%;
    font-size: 1.8rem;
    font-weight: 500;
    color: #000;
}
.main-left-section .left-btn button{
    font-size: 25px;
    border-radius: 15px;
    cursor: pointer;
    color: #fff;
    background-color: #3498DB;
    transition: all 0.3s ease;
    width: 200px;
    height: 50px;
    text-align: center;
}
.main-right-section{
    width: 50%;
}
.main-right-section .right-img{
    width: 100%;
    height: 600px;
}
.main-right-section .right-img img{
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

@media screen and (max-width:1024px) {
    .main-container{
        flex-direction: column;
        position: relative;
    }
    .main-left-section{
        position: absolute;
        width: 80%;
        align-items: flex-start;
        top: 10%;
        gap: 1rem;

    }
    .main-left-section .left-title{
        gap: 1rem;
    }
    .main-left-section .left-title h1{
        color: #000;
    }
    .main-left-section .left-title p{
        font-size: 1.5rem;
        width: 60%;
        color: #000;
    }
    .main-right-section{
        width: 100%;
    }
    .main-right-section .right-img{
        width: 100%;
        height: 500px;
    }
}
@media screen and (max-width:960px) {
    .main-left-section{
        width: 70%;
    }
    .main-left-section .left-title h1{
        font-size: 3.9rem;
    }
    .main-left-section .left-title p{
        font-size: 1.3rem;
    }
    .main-left-section .left-btn button{
        font-size: 20px;
        width: 180px;
        height: 45px;
    }
}
@media screen and (max-width:768px) {
    .main-left-section{
        width: 80%;
        top: 20%;
    }
    .main-left-section .left-title h1{
        font-size: 3.5rem;
    }
    .main-left-section .left-title p{
        font-size: 1.2rem;
    }
    .main-left-section .left-btn button{
        font-size: 18px;
        width: 160px;
        height: 40px;
    }
    .main-right-section .right-img{
        width: 100%;
        height: 450px;
    }
}
@media screen and (max-width:480px) {
    .main-left-section .left-title h1{
        font-size: 2.5rem;
    }
    .main-left-section .left-title p{
        font-size: 1rem;
        width: 100%;
    }
    .main-left-section .left-btn button{
        font-size: 15px;
        width: 140px;
        height: 35px;
    }
    .main-right-section .right-img{
        width: 100%;
        height: 400px;
    }
}
@media screen and (max-width:380px) {
    .main-left-section .left-title h1{
        font-size: 1rem;
        width: 50%;
    }
    .main-left-section .left-title p{
        font-size: 0.8rem;
        font-weight: 600;
        letter-spacing: 0px;
    }
    .main-left-section .left-btn button{
        font-size: 12px;
        width: 100px;
        height: 30px;
        text-align: center;
    }
    .main-right-section .right-img{
        width: 100%;
        height: 350px;
    }
    
}

