.XizmatTuri-container{
    padding: 20px;
    display: grid;
    gap: 2rem;
    margin: 0 auto;
}
.xiz-tur-title h1{
    font-size: 3rem;
}
.xiz-tur-cards{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2rem;
    /* justify-content: space-between; */
}
.xiz-tur-card{
    width: 100%;
    display: grid;
    gap: 1rem;
    padding: 20px 10px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    padding: 20px;
    margin: 0 auto;
}
.xiz-tur-card:hover{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}
.xiz-tur-card .card-items{
    width: 100%;
}
.xiz-tur-card .card-image-btn{
    display: flex;
    justify-content:space-between;
    align-items: flex-end;
}

.xiz-tur-card .card-image-btn button{
    width: 150px;
    height: 40px;
    transition: .5s all;
}
.xiz-tur-card .card-image-btn button:hover{
    background-color: #fff;
    border:2px solid #3498DB;
    color: #3498DB;

}
.xiz-tur-card .card-image-btn .card-image{
    width: 150px;
    height: 150px;
}
.xiz-tur-card .card-image-btn .card-image img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.xiz-tur-cards2{
    width: 100%;
    background: linear-gradient(to bottom, #3dd7dc 40%, #97F8FB);
    display: flex;
    align-items: flex-start;
    padding: 20px 0;
    justify-content: space-around;
    gap: 2rem;
    border-radius: 20px;
}
.xiz-tur-cards2 .card2-image{
    width: 550px;
    height: 500px;
}
.xiz-tur-cards2 .card2-image img{
    width: 100%;
    height: 100%;
    border-radius: 20px;
}
.xiz-tur-cards2 .card2-items{
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-height: 350px;
    padding: 20px;
    justify-content: space-between;
    align-content: center;
    border-radius: 20px;
}
.xiz-tur-cards2 .card2-items .card2-title{
    display: grid;
    gap: 1rem;
}
.xiz-tur-cards2 .card2-items .card2-title span{
    font-size: 2.5rem;
    color: #fff;
    letter-spacing: 2px;
    font-weight: bold;
}
.xiz-tur-cards2 .card2-items .card2-button button{
    font-size: 1.5rem;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.3s;
    width: 200px;
    padding: 15px 0;
}
@media screen and (max-width:1024px) {
    .xiz-tur-cards{
        grid-template-columns: repeat(2,1fr);
    }
    .xiz-tur-card .card-image-btn button{
        width: 130px;
    }
    .xiz-tur-cards2 .card2-image{
        width: 350px;
        height: 300px;
    }
    .xiz-tur-cards2{
        align-items: center;
        justify-content: space-evenly;
    }
    .xiz-tur-cards2 .card2-items{
        width: 50%;
        gap: 1rem;
    }
    .xiz-tur-cards2 .card2-items .card2-title span{
        font-size: 2rem;
    }
    .xiz-tur-cards2 .card2-items .card2-button button{
        width: 150px;
        padding: 10px 0;
    }
}
@media screen and (max-width:960px) {
    .xiz-tur-cards{
        grid-template-columns: repeat(1,1fr);
        align-content:flex-start;
    }
    .xiz-tur-cards2 .card2-items{
        width: 70%;
    }
    .xiz-tur-cards2 .card2-image{
        display: none;
    }
}
@media screen and (max-width: 768px) {
    .xiz-tur-title h1{
        font-size: 2rem;
    }
    .xiz-tur-cards{
        gap: 1rem;
    }
    .xiz-tur-card .card-image-btn button{
        width: 110px;
        height: 40px;
    }
    .xiz-tur-cards2 .card2-items{
        width: 60%;
        justify-content: space-around;
    }
    .xiz-tur-cards2 .card2-items .card2-title span{
        font-size: 1.5rem;
    }
    .xiz-tur-cards2 .card2-items .card2-title .card2-icon{
        font-size: 2rem;
    }
    .xiz-tur-cards2 .card2-items .card2-button button{
        font-size: 1.2rem;
    }
}
@media screen and (max-width:480px) {
    .xiz-tur-title h1{
        font-size: 1.5rem;
    }
    .xiz-tur-card .card-items h1{
        font-size: 1.3rem;
    }
    .xiz-tur-card .card-items p{
        font-size: 1rem;
    }
    .xiz-tur-card .card-image-btn button{
        padding: 8px 20px;
    }
    .xiz-tur-card .card-image-btn .card-image{
        width: 80px;
        height: 80px;
    }
    .xiz-tur-cards2 .card2-items{
        width: 80%;
    }
    .xiz-tur-cards2 .card2-items .card2-title span{
        font-size: 1.2rem;
    }
    .xiz-tur-cards2 .card2-items .card2-title .card2-icon{
        font-size: 2rem;
    }
    .xiz-tur-cards2 .card2-items .card2-button button{
        padding: 8px 20px;
        width: 100px;
    }
}
@media screen and (max-width:380px) {
    .xiz-tur-card .card-image-btn button{
        width: 100px;
        height: 40px;
    }
    .xiz-tur-cards2 .card2-items{
        width: 100%;
        max-height: 250px;
    }
    .xiz-tur-cards2 .card2-items .card2-title span{
        font-size: 1rem;
        text-align: left;
    }
    .xiz-tur-cards2 .card2-items .card2-title .card2-icon{
        font-size: 1.5rem;
    }
    .xiz-tur-cards2 .card2-items .card2-button button{
        font-size: 0.8rem;
        padding:10px;
    }
}