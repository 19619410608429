.about-container{
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 2rem;
    margin: 0 auto;
}
.about-title h1{
    font-size: 3rem;
}
.about-cards{
    display: flex;
    justify-content: space-between;
    align-items:center;
}
.about-card{
    width: 25%;
    display: flex;
    /* max-height: 400px; */
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    padding: 15px;
    gap: 1rem;
    border-radius: 10px;
}
.about-card .card-icon{
    font-size: 3.8rem;
    color: #344BDB;
}
.about-card .card-title{
    font-size: 2rem;
    width: 80%;
}
.about-card .card-descr{
    font-size: 1.2rem;
    width: 100%;
    color: #555;
}
.about-cards-two{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
    position: relative;
}
.about-card-two{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    gap: 1rem;
    border-radius: 10px;
    position: absolute;
    padding: 20px;
    left: 10%;
    z-index: 1;
}
.about-card-two .card-title-two{
    font-size: 3rem;
    width: 80%;
    color: #fff;
}
.about-card-two p{
    font-size: 1.5rem;
    width: 70%;
    color: #fff;
}
.about-card-two a button{
    width: 180px;
    border: none;
    padding: 15px 0;
    font-size: 20px;
    border-radius: 15px;
    cursor: pointer;
    color: #fff;
    background-color: #3498DB;
    transition: all 0.3s ease;
    text-align: center;
}
.about-img-container{
    width: 100%;
}
.about-img-container img{
    width: 100%;
    height: 600px;
    object-fit: cover;
    border-radius: 10px;

}

@media screen and (max-width:1024px) {
    .about-title h1{
        font-size: 2.5rem;
    }
    .about-cards{
        /* flex-direction: column; */
        display: grid;
        grid-template-columns: repeat(2,45%);
        width: 100%;
        gap: 2rem;
    }
    .about-card{
        width: 100%;
        /* max-height: 200px; */
    }
    .about-card .card-icon{
        font-size: 3rem;
    }
    .about-card .card-title{
        letter-spacing: 2px;
    }
    .about-card .card-descr{
        width: 100%;
    }
    .about-cards-two{
        align-items: center;
        overflow-x: hidden;
    }
    .about-card-two{
        overflow-x: hidden;
    }
    .about-card-two .card-title-two{
        width: 100%;
        font-size: 2.5rem;
    }
    .about-card-two p{
        font-size: 1.3rem;
    }
}
@media screen and (max-width:960px) {
    .about-title h1{
        font-size: 2rem;
    }
    .about-card .card-title{
        font-size: 1.5rem;
        width: 40%;
    }
    .about-card .card-descr{
        font-size: 1rem;
        width: 90%;
    }
    .about-card-two{
        top: 20%;
        width: 60%;
    }
    .about-card-two a button{
        width: 150px;
        padding: 10px 0;
    }
    .about-card-two .card-title-two{
        font-size: 2rem;
    }
    .about-img-container img{
        height: 500px;
        object-fit: none;
    }
}
@media screen and (max-width:768px) {
    .about-title h1{
        font-size: 1.8rem;
    }
    .about-cards{
        display: flex;
        flex-direction: column;
    }
    .about-card{
        width: 100%;
        max-height: 250px;
    }
    .about-card .card-title{
        font-size: 1.3rem;
        width: 50%;
    }
    .about-card .card-descr{
        font-size: 1rem;
        width: 90%;
    }
    .about-card-two{
        top: 25%;
    }
    .about-card-two p{
        font-size: 1.3rem;
    }
    .about-card-two a button{
        width: 100px;
        padding: 5px;
    }
}
@media screen and (max-width:480px) {
    .about-title h1{
        font-size: 1.5rem;
    }
    .about-card{
        padding: 10px 0 10px 10px;
    }
    .about-card .card-icon{
        font-size: 3rem;
    }
    .about-card .card-title{
        font-size: 1.2rem;
        width: 50%;
    }
    .about-card .card-descr{
        font-size: 1rem;
        width: 90%;
    }
    .about-card-two{
        top: 15%;
        width: 90%;
        /* right: 10%; */
    }
    .about-card-two .card-title-two{
        font-size: 1.3rem;
    }
    .about-card-two p{
        font-size: 1.1rem;
    }
    .about-img-container img{
        width: 100%;
        height: 400px;
    }
}
@media screen and (max-width:380px) {
    .about-title h1{
        font-size: 1.3rem;
    }
    .about-card{
        padding: 10px 0 10px 10px;
    }
    .about-card .card-icon{
        font-size: 2.5rem;
    }
    .about-card .card-title{
        font-size: 1rem;
        width: 50%;
    }
    .about-card .card-descr{
        font-size: 0.9rem;
        width: 90%;
    }
    .about-card-two{
        top: 15%;
        left: 0;
        width: 100%;
    }
    .about-card-two .card-title-two{
        font-size: 1rem;
    }
    .about-card-two p{
        font-size: 0.8rem;
    }
    .about-card-two a button{
        width: 100px;
        font-size: 15px;
    }
    .about-img-container{
        width: 100%;
    }
    .about-img-container img{
        height: 300px;
        object-fit: fill;
    }
}